// eslint-disable-next-line no-restricted-imports
import { Link as FuiLink, LinkProps } from '@fluentui/react-components';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

export const Link: React.FC<LinkProps> = (props) => {
    const navigate = useNavigate();

    const onClick = (event: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLButtonElement>) => {
        //if user clicks with ctrl or cmd key, let the browser handle the navigation to open in new tab
        if ('href' in props && props.href && !(event.ctrlKey || event.metaKey)) {
            navigate(props.href);
            event.preventDefault();
        }

        props.onClick?.(event);
    };

    return <FuiLink onClick={onClick} {...props} />;
};
