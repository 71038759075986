import { AuthError } from '@azure/msal-browser';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ICustomProperties, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AxiosError } from 'axios';

import { applicationInsights } from '../config';
import { sanitize } from './api';

const appInsights = new ApplicationInsights({
    config: {
        autoTrackPageVisitTime: false,
        disableAjaxTracking: true,
        disableCookiesUsage: true,
        enableAutoRouteTracking: false,
        extensions: [new ReactPlugin()],
        connectionString: applicationInsights.connectionString,

        disableExceptionTracking: !applicationInsights.enabled,
        disableFetchTracking: !applicationInsights.enabled,
        disableTelemetry: !applicationInsights.enabled,
    },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
    env.tags ??= [];
    env.tags['ai.cloud.role'] = 'Backoffice';
    env.tags['ai.application.ver'] = APP_VERSION;
});

export const identifyUser = (userId?: string) => {
    if (userId) {
        appInsights.setAuthenticatedUserContext(userId);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }
};

export const logError = (error: Error | unknown) => {
    const properties = getErrorProperties(error);

    if (error instanceof Error) {
        // for some reason calculation of the type name and message does not match normal web behavior
        Object.assign(error, {
            stack: getErrorStack(error),
        });

        appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error, properties });
    }

    console.error(error, properties);
};

const getErrorProperties = (error: Error | unknown): ICustomProperties | undefined => {
    const properties: ICustomProperties = {};
    if (error instanceof AuthError) {
        properties.errorCode = error.errorCode;
        properties.errorMessage = error.errorMessage;
        properties.errorName = error.name;
    } else if (error instanceof AxiosError) {
        properties.requestUrl = error.response?.config?.url;
        properties.requestMethod = error.response?.config?.method;

        properties.responseCode = error.response?.data?.code;
        properties.responseStatus = error.response?.status;

        properties.errorCode = error.code;
    } else if (error instanceof Error && error.cause instanceof Error) {
        return getErrorProperties(error.cause);
    }
    return sanitize(properties);
};

const getErrorStack = (error: unknown): string | undefined => {
    if (error instanceof Error) {
        const causeStack = getErrorStack(error.cause);

        if (causeStack) {
            return `${error.stack}\n\n-> ${causeStack}`;
        } else {
            return error.stack;
        }
    }
};
